import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    this.index = 0;
    this.total = this.containerTarget.children.length;
  }

  next() {
    this.index = (this.index + 1) % this.total;
    this.update();
  }

  prev() {
    this.index = (this.index - 1 + this.total) % this.total;
    this.update();
  }

  update() {
    this.containerTarget.style.transform = `translateX(-${this.index * 100}%)`;
  }
}
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["card", "dropdown", "expectedCash", "isUnlockEquity", "checkmark"];
  static values = { mortgageBalance: Number, data: Object, unlockEquity: String };

  connect() {
    const isUnlockEquityString = this.stripFormatting(this.isUnlockEquityTarget.value);
    if (isUnlockEquityString === "true") {
    } else {
      const ottoOption = this.dropdownTarget.querySelector('option[data-logo-class="logo-otto"]');
      if (ottoOption) {

        this.dropdownTarget.value = ottoOption.value;
        this.selectValue();
      }
    }

    this.cardTargets.forEach((card, index) => {
      if (index === 0) {
        this.selectCard(card);
      }
    });
  }

  select(event) {
    console.log("Card selected:", event.currentTarget);
    this.cardTargets.forEach((card) => {
      card.classList.remove("selected-card");
      card.querySelector(".checkmark-icon").classList.add("hidden");
    });
    this.selectCard(event.currentTarget);
  }

  selectCard(card) {
    card.classList.add("selected-card");
    card.querySelector(".checkmark-icon").classList.remove("hidden");
    const value = card.dataset.value;

    const event = new CustomEvent("valueSelected", {
      detail: { value },
      bubbles: true,
    });
    card.dispatchEvent(event);

    const isUnlockEquityString = this.stripFormatting(this.isUnlockEquityTarget.value);
    if (isUnlockEquityString === "true") {
      this.updatePage(value);
    }
  }

  stripFormatting(value) {
    return value.replace(/[\$,%]/g, "");
  }

  selectValue() {
    const selectedOption = this.dropdownTarget.options[this.dropdownTarget.selectedIndex];
    if (selectedOption) {
      this.updateLogo(selectedOption);
      this.updatePage(parseFloat(selectedOption.value));
    }
  }

  updatePage(selectedValue) {
    this.expectedCashTargets.forEach((element) => {
      const maxLtv = parseFloat(element.dataset.maxLtv);
      const cashValueElement = element.querySelector("[data-value-selection-target='cashValue']");
      if (cashValueElement) {
        const cashValue = this.calculateUnlockEquityExpectedCash(maxLtv, selectedValue, this.mortgageBalanceValue);
        cashValueElement.textContent = cashValue;
      }
    });
  }

  updateLogo(selectedOption) {
    const logoClass = selectedOption.dataset.logoClass;
    const logosContainer = document.getElementById("logos");
    const logoImage = logosContainer.querySelector(`.${logoClass}`);

    const selectedLogoDiv = document.getElementById("selected-logo");
    selectedLogoDiv.innerHTML = "";
    if (logoImage) {
      const cloneLogoImage = logoImage.cloneNode(true);
      cloneLogoImage.style.display = "block";
      cloneLogoImage.classList.add("object-contain", "h-full", "max-w-full");
      selectedLogoDiv.appendChild(cloneLogoImage);
    }
  }

  calculateUnlockEquityExpectedCash(maxLtv, currentHomeValue, mortgageBalance) {
    const cashOut = maxLtv * currentHomeValue - mortgageBalance;
    return this.formatCurrency(cashOut);
  }

  formatCurrency(value) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  }

  highlight(event) {
    event.currentTarget.classList.add("hover-card-class");
  }

  reset(event) {
    event.currentTarget.classList.remove("hover-card-class");
  }
}

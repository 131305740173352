import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export default class extends Controller {

  connect() {
    this.injectStyles();
    this.initializeGridSwiper();
    this.initializeSingleSwiper();
    this.initializeRowSwiper();
  }

  initializeGridSwiper() {
    this.gridSwiper = new Swiper('.mySwiper', {
      slidesPerView: 1,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1280: {
          slidesPerView: 3,
          spaceBetween: 30,
          grid: {
            rows: 2,
          }, 
        },
      },
    });
  }

  injectStyles(){

    const swiperStyles = `
      .swiper-button-prev, .swiper-button-next {
        background-color: rgba(0, 0, 0, 0.5);
        width: 4rem;
        height: 4rem; 
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
      }

      .swiper-button-prev:hover, .swiper-button-next:hover {
        background-color: rgba(0, 0, 0, 0.75);
      }
    `;

    const styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.innerText = swiperStyles;
    document.head.appendChild(styleSheet);
  }

  initializeSingleSwiper() {
    this.singleSwiper = new Swiper('.mySingleSwiper', {
      slidesPerView: 1,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }

  initializeRowSwiper() {
    this.singleSwiper = new Swiper('.myRowSwiper', {
      slidesPerView: 1,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1280: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
    });
  }

  disconnect() {
    if (this.gridSwiper) {
      this.gridSwiper.destroy();
      this.gridSwiper = null;
    }
    if (this.singleSwiper) {
      this.singleSwiper.destroy();
      this.singleSwiper = null;
    }
  }
}

/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto";

Chart.register({
  id: 'doughnutLabel',
  beforeDraw: function (chart) {
    if (chart.config.type !== 'doughnut') return;

    const width = chart.width;
    const height = chart.height;
    const ctx = chart.ctx;
    const text = chart.config.options.plugins.doughnutLabel?.text;

    if (!text) return;

    ctx.save();
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    ctx.font = 'bold 20px Arial';
    ctx.fillStyle = '#000';
    ctx.fillText(text.title, width / 2, height / 2 - 10);

    ctx.font = 'bold 24px Arial';
    ctx.fillStyle = '#000';
    ctx.fillText(text.value, width / 2, height / 2 + 20);

    ctx.restore();
  }
});

export default class extends Controller {
  static targets = ["trigger", "content", "cost", "percentage", "homeValue", "realtorCost", "realtorPercentage", "closingCosts", "closingCostsPercentage",
    "realtorBuyCost", "realtorBuyPercentage", "purchasePrice", "downPayment", "downPaymentPercentage", "estimatedProfit", "currentMortgageBalance", "estimatedCostsBuy",
    "estimatedMortgagePayment", "interestRate", "loanTerm", "loanAmount", "monthlyPaymentDifference", "currentHomePayment", "totalAfterThreeYears",
    "cumulativeMonthlyCostDifference", "equityOnNewHomePayments", "annualAppreciation", "equityAfterThreeYears", "newHomePurchaseText", "firstChartCanvas", "secondChartCanvas", "pocketSpent", "superTotal", "isCalculator", "profitDisplay", "costsBuyDisplay", "downPaymentDisplay", "monthlyCostDisplay", "firstChartWithLabel", "valuesList", "appreciatedValueDisplay", "equityOnNewHomePaymentsDisplay", "downPaymentDisplay2"]

  static values = { data: Object }

  connect() {
    this.updatePurchasePriceDependents();
    this.updateHomeValueDependents();

    const estimatedProfit = parseFloat(this.stripFormatting(this.estimatedProfitTarget.value));
    let downPayment = estimatedProfit * 0.5;
    const purchasePrice = parseFloat(this.stripFormatting(this.purchasePriceTarget.value));
    if (downPayment > purchasePrice * 0.2) {
      // this is to make sure the downpayment is capped at 20% of the purchase price
      downPayment = purchasePrice * 0.2;
    }
    this.downPaymentTarget.value = this.formatAsCurrency(downPayment);

    this.updateHomeValueDependents();
    this.updatePurchasePriceDependents();

    const totalAfterThreeYears = parseFloat(this.stripFormatting(this.totalAfterThreeYearsTarget.textContent));
    if (totalAfterThreeYears < 0) {
      downPayment = purchasePrice * 0.05;
      this.downPaymentTarget.value = this.formatAsCurrency(downPayment);
      this.updateHomeValueDependents();
      this.updatePurchasePriceDependents();
    }

    this.updateDownPaymentPercentage();
    this.updateLoanAmount();
    this.updateMortgagePayment();

    this.firstChart = null;
    this.updateFirstChartData();
    this.updateFirstChartWithLabel()
    this.updateSecondChartData();
    this.updateSuperTotal();
    this.publishValues();
    this.updateDisplayValues();

    this.handleInput = this.debounce(this.handleInput.bind(this), 300);
    this.updateHomeValueDependents = this.debounce(this.updateHomeValueDependents.bind(this), 300);
    this.updatePurchasePriceDependents = this.debounce(this.updatePurchasePriceDependents.bind(this), 300);
    this.updateDownPaymentPercentage = this.debounce(this.updateDownPaymentPercentage.bind(this), 300);
    this.updateLoanAmount = this.debounce(this.updateLoanAmount.bind(this), 300);
    this.updateEstimatedProfitValue = this.debounce(this.updateEstimatedProfitValue.bind(this), 300);
    this.updatePercentage = this.debounce(this.updatePercentage.bind(this), 300);
    this.updateValue = this.debounce(this.updateValue.bind(this), 300);
    this.updateRealtorPercentage = this.debounce(this.updateRealtorPercentage.bind(this), 300);
    this.updateRealtorValue = this.debounce(this.updateRealtorValue.bind(this), 300);
    this.updateRealtorBuyPercentage = this.debounce(this.updateRealtorBuyPercentage.bind(this), 300);
    this.updateRealtorBuyValue = this.debounce(this.updateRealtorBuyValue.bind(this), 300);
    this.updateClosingCostsPercentage = this.debounce(this.updateClosingCostsPercentage.bind(this), 300);
    this.updateClosingCostsValue = this.debounce(this.updateClosingCostsValue.bind(this), 300);
    this.updateMortgagePayment = this.debounce(this.updateMortgagePayment.bind(this), 300);
    this.updateMonthlyPaymentDifference = this.debounce(this.updateMonthlyPaymentDifference.bind(this), 300);
    this.updateNewHomePurchaseText = this.debounce(this.updateNewHomePurchaseText.bind(this), 300);
    this.updateEstimatedCostsBuy = this.debounce(this.updateEstimatedCostsBuy.bind(this), 300);
    this.updateSuperTotal = this.debounce(this.updateSuperTotal.bind(this), 300);
    this.updateFirstChartData = this.debounce(this.updateFirstChartData.bind(this), 300);
    this.updateFirstChartWithLabel = this.debounce(this.updateFirstChartWithLabel.bind(this), 300);
    this.updateSecondChartData = this.debounce(this.updateSecondChartData.bind(this), 300);
  }

  publishValues() {
    this.estimatedProfit = parseFloat(this.stripFormatting(this.estimatedProfitTarget.value));
    this.downPayment = parseFloat(this.stripFormatting(this.downPaymentTarget.value));
    this.estimatedCostsBuy = parseFloat(this.stripFormatting(this.estimatedCostsBuyTarget.value));
    this.cumulativeMonthlyCostDifference = parseFloat(this.stripFormatting(this.cumulativeMonthlyCostDifferenceTarget.value));
    this.equityOnNewHomePaymentsValue = parseFloat(this.stripFormatting(this.equityOnNewHomePaymentsTarget.value)) || 0;
    this.annualAppreciationPercentage = parseFloat(this.stripFormatting(this.annualAppreciationTarget.value.replace('%', ''))) || 0;
    const purchasePrice = parseFloat(this.stripFormatting(this.purchasePriceTarget.value));
    this.appreciatedValue = purchasePrice * ((1 + (this.annualAppreciationPercentage / 100)) ** 3 - 1);
  }

  updateDisplayValues() {
    this.profitDisplayTarget.textContent = this.formatCurrencyNumber(this.estimatedProfit);
    this.costsBuyDisplayTarget.textContent = this.formatCurrencyNumber(this.estimatedCostsBuy);
    this.downPaymentDisplayTarget.textContent = this.formatCurrencyNumber(this.downPayment);
    this.downPaymentDisplay2Target.textContent = this.formatCurrencyNumber(this.downPayment);
    this.monthlyCostDisplayTarget.textContent = this.formatCurrencyNumber(this.cumulativeMonthlyCostDifference);
    this.appreciatedValueDisplayTarget.textContent = this.formatCurrencyNumber(this.appreciatedValue);
    this.equityOnNewHomePaymentsDisplayTarget.textContent = this.formatCurrencyNumber(this.equityOnNewHomePaymentsValue);
  }

  formatCurrencyNumber(value) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value);
  }

  updateFirstChartWithLabel() {
    if (!this.hasFirstChartWithLabelTarget) return;

    const estimatedProfit = parseFloat(this.stripFormatting(this.estimatedProfitTarget.value));
    const downPayment = parseFloat(this.stripFormatting(this.downPaymentTarget.value));
    const estimatedCostsBuy = parseFloat(this.stripFormatting(this.estimatedCostsBuyTarget.value));
    const cumulativeMonthlyCostDifference = parseFloat(this.stripFormatting(this.cumulativeMonthlyCostDifferenceTarget.value));
    const totalAfterThreeYears = estimatedProfit - downPayment - estimatedCostsBuy - cumulativeMonthlyCostDifference;

    const profitColor = estimatedProfit >= 0 ? 'rgba(75, 192, 192, 0.7)' : 'rgba(255, 206, 86, 0.7)';
    const profitHoverColor = estimatedProfit >= 0 ? 'rgba(75, 192, 192, 1)' : 'rgba(255, 206, 86, 1)';
    const monthlyCostDiffColor = cumulativeMonthlyCostDifference >= 0 ? 'rgba(255, 206, 86, 0.7)' : 'rgba(75, 192, 192, 0.7)';
    const monthlyCostDiffHoverColor = cumulativeMonthlyCostDifference >= 0 ? 'rgba(255, 206, 86, 1)' : 'rgba(75, 192, 192, 1)';

    const chartData = {
      type: 'doughnut',
      data: {
        labels: ['Estimated Profit', 'Down Payment', 'Estimated Costs to Buy', 'Cumulative Monthly Cost Difference'],
        datasets: [{
          data: [
            Math.abs(estimatedProfit),
            downPayment,
            estimatedCostsBuy,
            Math.abs(cumulativeMonthlyCostDifference)
          ],
          backgroundColor: [
            profitColor,
            'rgba(235, 87, 87, 0.7)',
            'rgba(242, 153, 74, 0.7)',
            monthlyCostDiffColor
          ],
          hoverBackgroundColor: [
            profitHoverColor,
            'rgba(235, 87, 87, 1)',
            'rgba(242, 153, 74, 1)',
            monthlyCostDiffHoverColor
          ]
        }]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          doughnutLabel: {
            text: {
              title: 'Pocketed',
              value: `${"$" + totalAfterThreeYears.toLocaleString()}`
            }
          }
        }
      }
    }

    this.createOrUpdateChart(this.firstChartWithLabelTarget, chartData, 'firstChartWithLabel');
  }

  toggle(event) {
    event.preventDefault()
    const trigger = event.currentTarget
    const content = trigger.nextElementSibling

    if (content.classList.contains("hidden")) {
      content.classList.remove("hidden")
      trigger.textContent = "Collapse Inputs"
    } else {
      content.classList.add("hidden")
      trigger.textContent = "Show Inputs"
    }
  }

  debounce(func, delay) {
    let debounceTimer;
    return function (...args) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => { func.apply(this, args); }, delay);
    }
  }

  updateFirstChartData() {
    if (!this.hasFirstChartCanvasTarget) return;
    const estimatedProfit = parseFloat(this.stripFormatting(this.estimatedProfitTarget.value));
    const downPayment = parseFloat(this.stripFormatting(this.downPaymentTarget.value));
    const estimatedCostsBuy = parseFloat(this.stripFormatting(this.estimatedCostsBuyTarget.value));
    const cumulativeMonthlyCostDifference = parseFloat(this.stripFormatting(this.cumulativeMonthlyCostDifferenceTarget.value));

    // Conditional colors based on value
    const profitColor = estimatedProfit >= 0 ? 'rgba(75, 192, 192, 0.7)' : 'rgba(255, 206, 86, 0.7)';
    const profitHoverColor = estimatedProfit >= 0 ? 'rgba(75, 192, 192, 1)' : 'rgba(255, 206, 86, 1)';
    const monthlyCostDiffColor = cumulativeMonthlyCostDifference >= 0 ? 'rgba(255, 206, 86, 0.7)' : 'rgba(75, 192, 192, 0.7)';
    const monthlyCostDiffHoverColor = cumulativeMonthlyCostDifference >= 0 ? 'rgba(255, 206, 86, 1)' : 'rgba(75, 192, 192, 1)';

    const chartData = {
      type: 'doughnut',
      data: {
        labels: ['Estimated Profit', 'Down Payment', 'Estimated Costs to Buy', 'Cumulative Monthly Cost Difference'],
        datasets: [{
          data: [
            Math.abs(estimatedProfit),
            downPayment,
            estimatedCostsBuy,
            Math.abs(cumulativeMonthlyCostDifference)
          ],
          backgroundColor: [
            profitColor,
            'rgba(235, 87, 87, 0.7)',
            'rgba(242, 153, 74, 0.7)',
            // 'rgba(54, 162, 235, 0.7)', // Blue
            // 'rgba(255, 206, 86, 0.7)', // Yellow
            monthlyCostDiffColor
          ],
          hoverBackgroundColor: [
            profitHoverColor, // Darker teal for profit
            'rgba(235, 87, 87, 1)',
            'rgba(242, 153, 74, 1)',
            // 'rgba(255, 99, 132, 0.7)', // Red
            // 'rgba(54, 162, 235, 1)', // Darker blue for down payment
            // 'rgba(255, 206, 86, 1)', // Darker yellow for estimated costs
            monthlyCostDiffHoverColor  // Darker teal for savings
          ]
        }]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'right',
          }
        }
      }
    };

    this.createOrUpdateChart(this.firstChartCanvasTarget, chartData, 'firstChart');
  }

  updateSecondChartData() {
    if (!this.hasSecondChartCanvasTarget) return;
    const equityOnNewHomePayments = parseFloat(this.stripFormatting(this.equityOnNewHomePaymentsTarget.value));
    const purchasePrice = parseFloat(this.stripFormatting(this.purchasePriceTarget.value));
    const annualAppreciationPercentage = parseFloat(this.stripFormatting(this.annualAppreciationTarget.value.replace('%', '')));
    const appreciatedValue = purchasePrice * (1 + (annualAppreciationPercentage / 100)) ** 3 - purchasePrice;
    const downPayment = parseFloat(this.stripFormatting(this.downPaymentTarget.value));

    // Conditional colors based on value
    const avColor = appreciatedValue >= 0 ? 'rgba(75, 192, 192, 0.7)' : 'rgba(255, 206, 86, 0.7)';
    const avHoverColor = appreciatedValue >= 0 ? 'rgba(75, 192, 192, 1)' : 'rgba(255, 206, 86, 1)';


    const chartData = {
      type: 'doughnut',
      data: {
        labels: ['Appreciated Value', 'Down Payment', 'Equity on New Home Payments'],
        datasets: [{
          data: [
            Math.abs(appreciatedValue),
            downPayment,
            equityOnNewHomePayments
          ],
          backgroundColor: [
            avColor,
            'rgba(54, 162, 235, 0.7)',
            'rgba(255, 206, 86, 0.7)'
          ],
          hoverBackgroundColor: [
            avHoverColor,
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)'
          ]
        }]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'right',
          }
        }
      }
    };

    this.createOrUpdateChart(this.secondChartCanvasTarget, chartData, 'secondChart');
  }

  createOrUpdateChart(canvasTarget, chartData, chartInstanceName) {
    const ctx = canvasTarget.getContext('2d');
    if (this[chartInstanceName]) {
      this[chartInstanceName].data = chartData.data;
      this[chartInstanceName].options = chartData.options;
      this[chartInstanceName].update();
    } else {
      if (Chart.getChart(ctx)) {
        Chart.getChart(ctx).destroy();
      }
      this[chartInstanceName] = new Chart(ctx, chartData);
    }
  }


  handleInput(event) {
    const input = event.target;
    const value = this.stripFormatting(input.value);
    input.value = value;

    if (input === this.costTarget) {
      this.updatePercentage();
    } else if (input === this.percentageTarget) {
      this.updateValue();
    }
  }

  updateMortgagePayment() {
    this.updateFirstChartData();
    this.updateSecondChartData();
    this.updateFirstChartWithLabel()

    if (this.loanAmountTarget && this.interestRateTarget && this.loanTermTarget && this.estimatedMortgagePaymentTarget) {
      const principal = parseFloat(this.stripFormatting(this.loanAmountTarget.value));
      const annualInterestRate = parseFloat(this.stripFormatting(this.interestRateTarget.value.replace('%', '')));
      const loanTermMonths = parseInt(this.loanTermTarget.value);

      if (!isNaN(principal) && !isNaN(annualInterestRate) && !isNaN(loanTermMonths)) {
        const monthlyPayment = this.calculateMonthlyPayment(principal, annualInterestRate, loanTermMonths);
        this.estimatedMortgagePaymentTarget.value = this.formatAsCurrency(monthlyPayment);

        this.updateMonthlyPaymentDifference(monthlyPayment);
        const monthlyPaymentDifference = parseFloat(this.stripFormatting(this.monthlyPaymentDifferenceTarget.value));
        this.cumulativeMonthlyCostDifferenceTarget.value = this.formatAsCurrency(monthlyPaymentDifference * 36);

        const outstandingPrincipal = this.calculateOutstandingPrincipal();
        const loanAmount = parseFloat(this.stripFormatting(this.loanAmountTarget.value));

        this.equityOnNewHomePaymentsTarget.value = this.formatAsCurrency(loanAmount - outstandingPrincipal);

        const estimatedProfit = parseFloat(this.stripFormatting(this.estimatedProfitTarget.value));
        const downPayment = parseFloat(this.stripFormatting(this.downPaymentTarget.value));
        const estimatedCostsBuy = parseFloat(this.stripFormatting(this.estimatedCostsBuyTarget.value));
        const cumulativeMonthlyCostDifference = parseFloat(this.stripFormatting(this.cumulativeMonthlyCostDifferenceTarget.value));
        const totalAfterThreeYears = estimatedProfit - downPayment - estimatedCostsBuy - cumulativeMonthlyCostDifference;
        this.totalAfterThreeYearsTarget.textContent = this.formatAsCurrency(totalAfterThreeYears);

        const equityOnNewHomePayments = parseFloat(this.stripFormatting(this.equityOnNewHomePaymentsTarget.value));
        const purchasePrice = parseFloat(this.stripFormatting(this.purchasePriceTarget.value));
        const annualAppreciationPercentage = parseFloat(this.stripFormatting(this.annualAppreciationTarget.value.replace('%', '')));
        const appreciatedValue = purchasePrice * (1 + (annualAppreciationPercentage / 100)) ** 3 - purchasePrice;
        const equityAfterThreeYears = equityOnNewHomePayments + appreciatedValue + downPayment;

        // removed down payment calc
        this.equityAfterThreeYearsTarget.textContent = this.formatAsCurrency(equityAfterThreeYears);

      }
    }
  }

  updateMonthlyPaymentDifference(estimatedPayment) {
    const currentPayment = parseFloat(this.stripFormatting(this.currentHomePaymentTarget.value.replace('$', '')));
    if (!isNaN(currentPayment) && !isNaN(estimatedPayment)) {
      const paymentDifference = estimatedPayment - currentPayment;
      this.monthlyPaymentDifferenceTarget.value = this.formatAsCurrency(paymentDifference);
      const totalDifference = paymentDifference * 36;
      this.cumulativeMonthlyCostDifferenceTarget.value = this.formatAsCurrency(totalDifference);

      const estimatedProfit = parseFloat(this.stripFormatting(this.estimatedProfitTarget.value));
      const downPayment = parseFloat(this.stripFormatting(this.downPaymentTarget.value));
      const estimatedCostsBuy = parseFloat(this.stripFormatting(this.estimatedCostsBuyTarget.value));
      const cumulativeMonthlyCostDifference = parseFloat(this.stripFormatting(this.cumulativeMonthlyCostDifferenceTarget.value));
      const totalAfterThreeYears = estimatedProfit - downPayment - estimatedCostsBuy - cumulativeMonthlyCostDifference;
      this.totalAfterThreeYearsTarget.textContent = this.formatAsCurrency(totalAfterThreeYears);
    }
  }

  calculateMonthlyPayment(principal, annualInterestRate, loanTermMonths) {
    const monthlyInterestRate = annualInterestRate / 12 / 100;
    const pow = Math.pow(1 + monthlyInterestRate, loanTermMonths);
    const numerator = monthlyInterestRate * pow;
    const denominator = pow - 1;

    return principal * (numerator / denominator);
  }

  calculateOutstandingPrincipal() {
    const principal = parseFloat(this.stripFormatting(this.loanAmountTarget.value));
    const annualInterestRate = parseFloat(this.stripFormatting(this.interestRateTarget.value.replace('%', '')));
    const loanTermMonths = parseInt(this.loanTermTarget.value);

    if (!isNaN(principal) && !isNaN(annualInterestRate) && !isNaN(loanTermMonths)) {
      const monthlyInterestRate = annualInterestRate / 12 / 100;

      const monthlyPayment = this.calculateMonthlyPayment(principal, annualInterestRate, loanTermMonths);

      // Calculate the outstanding balance after 36 payments
      const pow = Math.pow(1 + monthlyInterestRate, 36);
      const firstPart = pow * principal;
      const numerator = monthlyPayment * (pow - 1);
      const secondPart = numerator / monthlyInterestRate;
      const outstandingPrincipal = firstPart - secondPart;

      return outstandingPrincipal;
    } else {
      console.error('Invalid input for mortgage calculations.');
      return 0;
    }
  }

  convertYearsToMonths(term) {
    // Assuming the term is input as '30 Year Fixed', extract the numeric part
    const match = term.match(/(\d+)/);
    if (match) return parseInt(match[1]) * 12;
    return 0;  // Default to 0 if no numeric part found
  }

  stripFormatting(value) {
    return value.trim().replace(/[^\d.-]/g, '');
  }  


  formatCurrency(event) {
    const input = event.target;
    const value = parseFloat(this.stripFormatting(input.value));
    if (!isNaN(value)) {
      input.value = this.formatAsCurrency(value);
    }
  }

  formatPercentage(event) {
    const input = event.target;
    const value = parseFloat(input.value.replace(/%/g, ''));
    if (!isNaN(value)) {
      input.value = `${value.toFixed(1)}%`;
    }
  }

  formatAsCurrency(value) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    }).format(value);
  }

  updatePercentage() {
    const costInput = this.costTarget;
    const percentageInput = this.percentageTarget;
    const homeValue = parseFloat(this.stripFormatting(this.homeValueTarget.value));
    const currentValue = parseFloat(this.stripFormatting(costInput.value));
    if (!isNaN(homeValue) && homeValue !== 0) {
      const percentage = ((currentValue / homeValue)) * 100;
      percentageInput.value = `${percentage.toFixed(1)}%`;
    }
    this.updateEstimatedProfitValue();
  }


  updateValue() {
    const percentage = parseFloat(this.stripFormatting(this.percentageTarget.value.replace(/%/g, '')));
    const homeValue = parseFloat(this.stripFormatting(this.targets.find("homeValue").value));
    if (!isNaN(percentage) && !isNaN(homeValue)) {
      const newValue = (homeValue * (percentage / 100)).toFixed(2);
      this.costTarget.value = this.formatAsCurrency(newValue);
    }
    this.updateEstimatedProfitValue();
  }

  updateEstimatedProfitValue() {
    const homeValue = parseFloat(this.stripFormatting(this.homeValueTarget.value));
    const currentMortgageBalance = parseFloat(this.stripFormatting(this.currentMortgageBalanceTarget.value));
    const estCostsSell = parseFloat(this.stripFormatting(this.costTarget.value));
    const realtorCommissions = parseFloat(this.stripFormatting(this.realtorCostTarget.value));
    const downPayment = parseFloat(this.stripFormatting(this.downPaymentTarget.value));
    const estimatedCostsBuy = parseFloat(this.stripFormatting(this.estimatedCostsBuyTarget.value));
    const cumulativeMonthlyCostDifference = parseFloat(this.stripFormatting(this.cumulativeMonthlyCostDifferenceTarget.value));


    if (!isNaN(homeValue) && !isNaN(currentMortgageBalance) && !isNaN(estCostsSell) && !isNaN(realtorCommissions)) {
      const estimatedProfit = homeValue - currentMortgageBalance - estCostsSell - realtorCommissions;
      const totalAfterThreeYears = estimatedProfit - downPayment - estimatedCostsBuy - cumulativeMonthlyCostDifference;
      this.estimatedProfitTarget.value = this.formatAsCurrency(estimatedProfit);
      const isCalculatorString = this.stripFormatting(this.isCalculatorTarget.value)
      if (totalAfterThreeYears >= 0) {
        if (isCalculatorString === "true") {
          this.pocketSpentTarget.textContent = 'pocketed';
        } else {
          this.pocketSpentTarget.textContent = 'pocket';
        }
      } else {
        if (isCalculatorString === "true") {
          this.pocketSpentTarget.textContent = 'spent';
        } else {
          this.pocketSpentTarget.textContent = 'spend';
        }
      }
    } else {
      console.log('else trigger for pocketSpent')
      this.estimatedProfitTarget.value = this.formatAsCurrency(0);
    }
  }

  updateSuperTotal() {
    const equityAfterThreeYears = parseFloat(this.stripFormatting(this.equityAfterThreeYearsTarget.textContent));
    const totalAfterThreeYears = parseFloat(this.stripFormatting(this.totalAfterThreeYearsTarget.textContent));
    const superTotal = equityAfterThreeYears + totalAfterThreeYears;
    this.superTotalTarget.textContent = this.formatAsCurrency(superTotal);
  }

  updateEstimatedCostsBuy() {
    const realtorBuyCost = parseFloat(this.stripFormatting(this.realtorBuyCostTarget.value));
    const closingCosts = parseFloat(this.stripFormatting(this.closingCostsTarget.value));

    if (!isNaN(realtorBuyCost) && !isNaN(closingCosts)) {
      const estimatedCostsBuy = realtorBuyCost + closingCosts;
      this.estimatedCostsBuyTarget.value = this.formatAsCurrency(estimatedCostsBuy);
    } else {
      this.estimatedCostsBuyTarget.value = this.formatAsCurrency(0);
    }
    this.updateFirstChartData();
    this.updateFirstChartWithLabel()

  }

  updateRealtorPercentage() {
    const realtorCost = parseFloat(this.stripFormatting(this.realtorCostTarget.value));
    const homeValue = parseFloat(this.stripFormatting(this.homeValueTarget.value));
    if (!isNaN(realtorCost) && !isNaN(homeValue) && homeValue !== 0) {
      const percentage = (realtorCost / homeValue) * 100;
      this.realtorPercentageTarget.value = `${percentage.toFixed(1)}%`;
    }
    this.updateEstimatedProfitValue();
  }

  updateRealtorValue() {
    const percentage = parseFloat(this.stripFormatting(this.realtorPercentageTarget.value.replace(/%/g, '')));
    const homeValue = parseFloat(this.stripFormatting(this.homeValueTarget.value));
    if (!isNaN(percentage) && !isNaN(homeValue)) {
      const newValue = (homeValue * (percentage / 100)).toFixed(2);
      this.realtorCostTarget.value = this.formatAsCurrency(newValue);
    }
    this.updateEstimatedProfitValue();
  }

  updateRealtorBuyPercentage() {
    const realtorBuyCost = parseFloat(this.stripFormatting(this.realtorBuyCostTarget.value));
    const purchasePrice = parseFloat(this.stripFormatting(this.purchasePriceTarget.value));
    if (!isNaN(realtorBuyCost) && !isNaN(purchasePrice) && purchasePrice !== 0) {
      const percentage = (realtorBuyCost / purchasePrice) * 100;
      this.realtorBuyPercentageTarget.value = `${percentage.toFixed(1)}%`;
    }
    this.updateEstimatedCostsBuy();
  }

  updateRealtorBuyValue() {
    const percentage = parseFloat(this.stripFormatting(this.realtorBuyPercentageTarget.value.replace(/%/g, '')));
    const purchasePrice = parseFloat(this.stripFormatting(this.purchasePriceTarget.value));
    if (!isNaN(percentage) && !isNaN(purchasePrice)) {
      const newValue = (purchasePrice * (percentage / 100)).toFixed(2);
      this.realtorBuyCostTarget.value = this.formatAsCurrency(newValue);
    }
    this.updateEstimatedCostsBuy();
  }

  updateClosingCostsPercentage() {
    const closingCosts = parseFloat(this.stripFormatting(this.closingCostsTarget.value));
    const purchasePrice = parseFloat(this.stripFormatting(this.purchasePriceTarget.value));
    if (!isNaN(closingCosts) && !isNaN(purchasePrice) && purchasePrice !== 0) {
      const percentage = (closingCosts / purchasePrice) * 100;
      this.closingCostsPercentageTarget.value = `${percentage.toFixed(1)}%`;
    }
    this.updateEstimatedCostsBuy();
  }

  updateLoanAmount() {
    const purchasePrice = parseFloat(this.stripFormatting(this.purchasePriceTarget.value));
    const downPayment = parseFloat(this.stripFormatting(this.downPaymentTarget.value));
    if (!isNaN(purchasePrice) && !isNaN(downPayment)) {
      const newValue = (purchasePrice - downPayment).toFixed(0);
      this.loanAmountTarget.value = this.formatAsCurrency(newValue);
    }
  }

  updateClosingCostsValue() {
    const percentage = parseFloat(this.stripFormatting(this.closingCostsPercentageTarget.value.replace(/%/g, '')));
    const purchasePrice = parseFloat(this.stripFormatting(this.purchasePriceTarget.value));
    if (!isNaN(percentage) && !isNaN(purchasePrice)) {
      const newValue = (purchasePrice * (percentage / 100)).toFixed(2);
      this.closingCostsTarget.value = this.formatAsCurrency(newValue);
    }
    this.updateEstimatedCostsBuy();
  }

  updateNewHomePurchaseText() {
    const purchasePrice = parseFloat(this.stripFormatting(this.purchasePriceTarget.value));
    if (!isNaN(purchasePrice)) {
      this.newHomePurchaseTextTarget.textContent = this.formatAsCurrency(purchasePrice);
    }
  }

  updatePurchasePriceDependents() {
    this.updateDownPaymentPercentage();
    this.updateRealtorBuyPercentage();
    this.updateClosingCostsPercentage();
    this.updateEstimatedCostsBuy()
    this.updateLoanAmount();
    this.updateMortgagePayment();
    this.updateMonthlyPaymentDifference()
    this.updateNewHomePurchaseText();
    this.updateSuperTotal();

  }

  updateHomeValueDependents() {
    this.updatePercentage();
    this.updateRealtorPercentage();
    this.updateEstimatedProfitValue();
    this.updateMortgagePayment();
    this.updateSuperTotal();
  }

  updateDownPaymentPercentage() {
    const purchasePrice = parseFloat(this.stripFormatting(this.purchasePriceTarget.value));
    let downPayment = parseFloat(this.stripFormatting(this.downPaymentTarget.value));
    let callAgain = false
    if (downPayment > purchasePrice) {
      callAgain = true
      downPayment = purchasePrice
    }
    if (!isNaN(purchasePrice) && purchasePrice !== 0) {
      const percentage = (downPayment / purchasePrice) * 100;
      this.downPaymentPercentageTarget.value = `${percentage.toFixed(1)}%`;
      this.updateMortgagePayment();
      if (callAgain) {
        this.updateDownPaymentValue();
      }
    }
  }

  updateDownPaymentValue() {
    let percentage = parseFloat(this.stripFormatting(this.downPaymentPercentageTarget.value.replace(/%/g, '')));
    const callAgain = false
    if (percentage > 100) {
      const callAgain = true
      percentage = 100
    }
    const purchasePrice = parseFloat(this.stripFormatting(this.purchasePriceTarget.value));
    if (!isNaN(percentage) && !isNaN(purchasePrice)) {
      const newValue = (purchasePrice * (percentage / 100)).toFixed(2);
      this.downPaymentTarget.value = this.formatAsCurrency(newValue);
      this.updateMortgagePayment();
      if (callAgain) {
        this.updateDownPaymentPercentage();
      }
    }
  }

  disconnect() {
    if (this.firstChart) {
      this.firstChart.destroy();
      this.firstChart = null;
    }
    if (this.secondChart) {
      this.secondChart.destroy();
      this.secondChart = null;
    }
  }

}
document.addEventListener("turbo:frame-missing", (event) => {
    const frame = event.target;
    if (frame instanceof HTMLElement) {
      frame.style.display = "none";
    }
  });
  
  document.addEventListener("turbo:fetch-request-error", (event) => {
    const frame = event.target;
    const status = event.detail?.error?.response?.status;
    
    if (frame instanceof HTMLElement && status === 503) {
      frame.style.display = "none";
    }
  });
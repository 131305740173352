import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modal', 'link', 'manualLink'];

  connect() {
    const popTime = this.element.dataset.modalPop;
    const modalSessionKey = `hasSeen ${this.element.dataset.modalPop}`;
    const seenModal = localStorage.getItem(modalSessionKey);
    if (this.hasLinkTarget && !seenModal) {
      setTimeout(() => {
        this.linkTarget.click();
        localStorage.setItem(modalSessionKey, 'true');
      }, popTime);
    }
  }

  open(event) {
    event.preventDefault();
    if (this.hasManualLinkTarget) {
      this.manualLinkTarget.click();
    }
  }

  hideModal() {
    this.element.parentElement.removeAttribute('src');
    this.element.remove();
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal();
    }
  }

  hide(event) {
    if (event) {
      event.preventDefault();
    }
    this.modalTarget.classList.add('hidden');
    this.modalTarget.classList.remove('flex');
  }

  show() {
    this.modalTarget.classList.add('flex');
    this.modalTarget.classList.remove('hidden');
  }

  stopPropagation(event) {
    event.stopPropagation();
  }
}
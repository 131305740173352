document.addEventListener('DOMContentLoaded', () => {
    const shareButton = document.getElementById('share-button');
  
    if (!shareButton) return;
  
    shareButton.addEventListener('click', async () => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: 'Here is an interesting report I found for my home:\n\n' +
                  shareButton.dataset.shareUrl,
            text: 'Check out this Home Value report',
          });
          console.log('Report shared successfully');
        } catch (error) {
          console.error('Error sharing the report:', error);
        }
      } else {
        alert('Web Share API is not supported in your browser.');
      }
    });
  });
  
window.Previous = function () {
  window.history.back();
};

// Reset File Input
window.resetFile = function () {
  const fileinputblock = document.getElementsByClassName("fileinputblock")[0];
  const file = document.querySelector(".file");
  file.value = "";
  file.style.display = "none";
  fileinputblock.style.display = "none";
};

window.checkFile = function (evt) {
  const fileinputblock = document.getElementsByClassName("fileinputblock")[0];
  const files = evt.target.files;
  const file = files[0];
  if (file.name === "") {
    fileinputblock.style.display = "none";
  } else {
    fileinputblock.style.display = "block";
    const file = document.querySelector(".file");
    file.style.display = "block";
  }
};
// Tab Content
window.tabLevel01 = function (evt, tabName) {
  // Declare all variables
  let i, tabcontent, tablinks;

  // Get all elements with class="tabcontent" and hide them
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }

  // Get all elements with class="tablinks" and remove the class "active"
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }

  // Show the current tab, and add an "active" class to the button that opened the tab
  document.getElementById(tabName).style.display = "flex";
  evt.currentTarget.className += " active";
};

window.tabLevel02 = function (evt2, tabName) {
  // Declare all variables
  let j, tabcontentlevel02, tablinkslevel02;

  // Get all elements with class="tabcontent" and hide them
  tabcontentlevel02 = document.getElementsByClassName("tabcontentlevel02");
  for (j = 0; j < tabcontentlevel02.length; j++) {
    tabcontentlevel02[j].style.display = "none";
  }

  // Get all elements with class="tablinks" and remove the class "active"
  tablinkslevel02 = document.getElementsByClassName("tablinkslevel02");
  for (j = 0; j < tablinkslevel02.length; j++) {
    tablinkslevel02[j].className = tablinkslevel02[j].className.replace(
      " active",
      "",
    );
  }

  // Show the current tab, and add an "active" class to the button that opened the tab
  document.getElementById(tabName).style.display = "flex";
  evt2.currentTarget.className += " active";
};

window.pageHeight = function () {
  const footer = document.getElementsByTagName("footer")[0];
  console.log("footer", footer);
  if (window.innerHeight > document.body.clientHeight) {
    footer.style.position = "fixed";
    footer.style.bottom = "0";
    footer.style.width = "100%";
  } else {
    footer.style.position = "unset";
  }
};

window.collapseDiv = function () {
  const x = document.getElementById("payment-breakdown");
  const y = document.getElementsByClassName("updownarrow")[0];
  if (x.style.display === "none") {
    x.style.display = "block";
    y.style.transform = "rotate(180deg)";
  } else {
    x.style.display = "none";
    y.style.transform = "rotate(0deg)";
  }
};

window.valueChange = function () {
  const value = $(this).val();
  if (value === undefined || isNaN(value) || value <= 0) {
    $(this).val(1);
  } else if (value >= 101) {
    $(this).val(100);
  }
};

window.showTab = function (n) {
  const x = document.getElementsByClassName("tab");
  const y = document.getElementsByClassName("step");

  x[n].style.display = "flex";
  y[n].style.display = "flex";

  for (let i = 0; i < x.length; i++) {
    if (i !== n) {
      x[i].style.display = "none";
      y[i].style.display = "none";
    }
  }

  // eslint-disable-next-line
  if (n == x.length - 1) {
    document.getElementById("nextBtn").innerHTML = "Submit Offer";
    document.getElementById("nextBtn").classList.remove("basis-1/2");
    document.getElementById("nextBtn").style.width = "100%";
    document.getElementById("saveDraftBtn").style.display = "none";
  } else {
    document.getElementById("nextBtn").innerHTML = "Continue";
  }
};

window.nextPrev = function (n) {
  const x = document.getElementsByClassName("tab");
  const y = document.getElementsByClassName("step");
  if (window.currentTab >= x.length - 1) {
    window.location.href = "/offersubmitted";
    return false;
  } else {
    x[window.currentTab].style.display = "none";
    y[window.currentTab].style.display = "none";
    window.currentTab = window.currentTab + n;
    showTab(window.currentTab);
  }
};

window.collapseDivButton = function (el) {
  const x = el.getElementsByClassName("collapsible-content")[0];
  const y = el.getElementsByClassName("updownarrow")[0];
  if (x.style.display === "none" || x.style.display === "") {
    x.style.display = "block";
    y.style.transform = "rotate(180deg)";
  } else {
    x.style.display = "none";
    y.style.transform = "rotate(0deg)";
  }
};

window.collapseButton = function () {
  const x = document.getElementsByClassName("collapsible")[0];
  const y = document.getElementsByClassName("updownarrow")[0];
  if (x.style.display === "none") {
    x.style.display = "block";
    y.style.transform = "rotate(180deg)";
  } else {
    x.style.display = "none";
    y.style.transform = "rotate(0deg)";
  }
};

// Close button and clear text used on searchmap.erb
window.ClearText = function () {
  document.getElementById("searchLoc").value = "";
};

window.showClose = function () {
  const but = document.getElementsByClassName("small-close-button")[0];
  const input = document.getElementById("searchLoc");
  if (input.value === "") {
    but.style.display = "none";
  } else {
    but.style.display = "block";
  }
};

window.showMapView = function () {
  const searchbox = document.getElementById("searchBox");
  const listresults = document.getElementById("searchResultsList");
  const map = document.getElementById("searchMap");
  const filters = document.getElementById("searchFilters");

  if (window.matchMedia("(max-width:767px)").matches) {
    searchbox.style.display = "block";
    listresults.style.display = "none";
    map.style.display = "block";
    filters.style.display = "none";
  } else {
    searchbox.style.display = "block";
    listresults.style.display = "block";
    map.style.display = "block";
    filters.style.display = "none";
  }
};

window.showListView = function () {
  const divtoShow = document.getElementById("searchResultsList");
  const divtoHide1 = document.getElementById("searchMap");
  const divtoHide2 = document.getElementById("searchFilters");

  divtoShow.style.display = "block";
  divtoHide1.style.display = "none";
  divtoHide2.style.display = "none";
};

window.showFilterView = function () {
  const searchbox = document.getElementById("searchBox");
  const listresults = document.getElementById("searchResultsList");
  const map = document.getElementById("searchMap");
  const filters = document.getElementById("searchFilters");

  searchbox.style.display = "none";
  listresults.style.display = "none";
  map.style.display = "none";
  filters.style.display = "block";
};

window.showPriorityMessage = function () {
  const stdMessage = document.getElementsByClassName("std-msg-inbox")[0];
  const priorityMessage =
    document.getElementsByClassName("priority-msg-inbox")[0];

  stdMessage.style.display = "none";
  priorityMessage.style.display = "flex";
};

window.showAllMessage = function () {
  const stdMessage = document.getElementsByClassName("std-msg-inbox")[0];
  const priorityMessage =
    document.getElementsByClassName("priority-msg-inbox")[0];

  stdMessage.style.display = "flex";
  priorityMessage.style.display = "flex";
};

window.showChat = function () {
  if (window.matchMedia("(max-width:767px)").matches) {
    var chat = document.getElementById("chat");
    var inbox = document.getElementById("inbox");
    chat.style.display = "flex";
    inbox.style.display = "none";
  } else {
    chat.style.display = "flex";
    inbox.style.display = "block";
  }
};

window.hideChat = function () {
  if (window.matchMedia("(max-width:767px)").matches) {
    var chat = document.getElementById("chat");
    var inbox = document.getElementById("inbox");
    chat.style.display = "none";
    inbox.style.display = "block";
  } else {
    chat.style.display = "flex";
    inbox.style.display = "block";
  }
};

let pos = { top: 0, left: 0, x: 0, y: 0 };

const mouseDownHandler = function (e) {
  const ele = document.getElementById("offercontainer");

  ele.style.cursor = "grabbing";
  ele.style.userSelect = "none";

  pos = {
    // The current scroll
    left: ele.scrollLeft,
    top: ele.scrollTop,
    // Get the current mouse position
    x: e.clientX,
    y: e.clientY,
  };

  document.addEventListener("mousemove", mouseMoveHandler);
  document.addEventListener("mouseup", mouseUpHandler);
};

const mouseMoveHandler = function (e) {
  const ele = document.getElementById("offercontainer");
  // How far the mouse has been moved
  const dx = e.clientX - pos.x;
  const dy = e.clientY - pos.y;

  // Scroll the element
  ele.scrollTop = pos.top - dy;
  ele.scrollLeft = pos.left - dx;
};

const mouseUpHandler = function () {
  const ele = document.getElementById("offercontainer");

  document.removeEventListener("mousemove", mouseMoveHandler);
  document.removeEventListener("mouseup", mouseUpHandler);

  ele.style.cursor = "grab";
  ele.style.removeProperty("user-select");
};
window.currentTab = 0;

document.addEventListener("DOMContentLoaded", function (event) {
  // stepper tab only on makeoffer view
  if (window.location.href.includes("users/edit")) {
    console.log("user edit loaded");
    document.getElementById("dob").type = "text";
  }

  if (window.location.href.includes("makeoffer")) {
    showTab(window.currentTab);
  }

  if (window.location.href.includes("dashboard")) {
    const ele = document.getElementById("offercontainer");
    // ele.scrollTop = 100;
    ele.style.cursor = "grab";
    ele.style.overflow = "auto";

    document.addEventListener("mousedown", mouseDownHandler);

    // ele.scrollLeft = 150;
  }

  if (window.location.href.includes("search")) {
    const rangeInput = document.querySelectorAll(".range-input input");
      const priceInput = document.querySelectorAll(".price-input input");
      const range = document.querySelector(".slider .progress");
    const priceGap = 1000;

    priceInput.forEach((input) => {
      input.addEventListener("input", (e) => {
        const minPrice = parseInt(priceInput[0].value);
          const maxPrice = parseInt(priceInput[1].value);

        if (maxPrice - minPrice >= priceGap && maxPrice <= rangeInput[1].max) {
          if (e.target.className === "input-min") {
            rangeInput[0].value = minPrice;
            range.style.left = (minPrice / rangeInput[0].max) * 100 + "%";
          } else {
            rangeInput[1].value = maxPrice;
            range.style.right =
              100 - (maxPrice / rangeInput[1].max) * 100 + "%";
          }
        }
      });
    });

    rangeInput.forEach((input) => {
      input.addEventListener("input", (e) => {
        const minVal = parseInt(rangeInput[0].value);
          const maxVal = parseInt(rangeInput[1].value);

        if (maxVal - minVal < priceGap) {
          if (e.target.className === "range-min") {
            rangeInput[0].value = maxVal - priceGap;
          } else {
            rangeInput[1].value = minVal + priceGap;
          }
        } else {
          priceInput[0].value = minVal;
          priceInput[1].value = maxVal;
          range.style.left = (minVal / rangeInput[0].max) * 100 + "%";
          range.style.right = 100 - (maxVal / rangeInput[1].max) * 100 + "%";
        }
      });
    });
  }
});

document.addEventListener('DOMContentLoaded', function () {
  const trackedLinks = document.querySelectorAll('.ui-tracked-link');

  trackedLinks.forEach(function (link) {
    link.addEventListener('click', function (e) {
      // e.preventDefault();

      // const user_id = link.getAttribute('data-user-id')
      const action_item = link.getAttribute('data-action-item'); // Get user_id from the link's data attribute
      const destination_url = link.getAttribute('href');
      const tn_id = link.getAttribute('data-tn-id')

      fetch('/user_interactions/log_action', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({
          // user_id: user_id,
          destination_url,
          action_item,
          tn_id
        })
      });
    });
  });
});
